<template>
  
  <div id="zl" class="zl" >
    <!-- <div class="rightlogo"></div> -->
    <div class="box-header" :style="scllorTp?'background:#fff':'  background: rgba(255, 255, 255, 0.5);'">
       
        <p>
            <a-button  @click="backFun"  style="margin-right:20px;" size="small"><a-icon type="home" /></a-button>
          <a href="https://www.educg.net/">
            <img src="../assets/logo (2).png" width="100px" class="minH" /><span class="minF"
              style="font-size: 16px; color: #666;    margin-left: 10px;"
              ></span
            >
          </a>
        </p>
        <p style="font-size:16px; color:rgb(53, 53, 53);font-weight: bold;line-height: 24px;" class="divider">服务热线：<i style="font-size:30px;color:#de7603">4000697117</i><a-icon type="phone" style="color:#de7603"/></p>

        
      </div>
    <div class="zl_box">
      <!-- <div class="mask"></div> -->
    
      <div class="conDD">
        <div class="container">
                  <!-- <img src="../assets/cgb.png" width="100%" /> -->
        <h1>{{titleD}}</h1>
        <p style="width:100px;border-top:2px solid #fff;margin:0 auto"></p>
        <!-- <div  class='sc-window' style="color:#505050;margin-top:50px;font-size:16px; position: relative;
    z-index: 99;">可全面支撑计算机 、 人工智能 、 大数据 、 机器人 、 区块链 、 智能医学工程 、 金融科技 、 电子信息 、网络安全等专业建设 <div style="margin-top:10px;">实现了“任何人、任何时间、任何地点均能开展实验学习”的目标.</div></div> -->
        <!-- <p class="kh"><span>具有专业深度</span> <span>安全可扩展</span></p> -->

        </div>
        
      </div>
    </div>
    <!-- <a-divider style="margin:30px 0;font-size:20px;font-weight: bold; color:#636160;" >  <a-icon type="bulb" style=""/>产品垂询 <span style="font-size:14px;color:#de7603">【填写信息，专属客服为您一对一服务】</span></a-divider> -->
    <div class="con">
      <!-- <a-tabs default-active-key="1" @change="tabcallback">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="bulb" />
            产 品 垂 询
          </span> -->
         
          <a-form-model
            v-if="subSure"
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
          >
          <h4 class="from-title">产品垂询 <span style="color:rgb(222, 118, 3);font-size: 14px;;">【填写信息，专属客服为您一对一服务】</span></h4>
            <a-form-model-item label="选择身份" prop="authtype">
              <a-select
                v-model="form.authtype"
                placeholder="请选择身份"
                @change="authtypeFun"
              >
              <!-- <a-select-option value="教师或学生"> 教师或学生 </a-select-option> -->
                <a-select-option value="企业合作"> 企业合作 </a-select-option>
                <a-select-option value="高校教师"> 高校教师 </a-select-option>
             
              </a-select>
            </a-form-model-item>
            <!-- <a-form-model-item
              label="身份"
              prop="authtype"
              style="visibility: hidden"
            >
              <a-select v-model="form.authtype" placeholder="请选择身份">
                <a-select-option value="shanghai"> 身份一 </a-select-option>
                <a-select-option value="beijing"> 身份二 </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <a-form-model-item ref="name" label="您的姓名" prop="name">
              <a-input v-model="form.name" v-decorator="['name']" />
            </a-form-model-item>
            <a-form-model-item ref="phone" label="手机号" prop="phone">
              <a-input v-model="form.phone" />
            </a-form-model-item>

            <a-form-model-item
              label="公司名称"
              prop="companyW"
              v-show="form.authtype == '企业合作'"
            >
              <a-input v-model="form.companyW" />
            </a-form-model-item>
            <a-form-model-item
              label="职务"
              prop="position"
              v-show="form.authtype == '企业合作'"
            >
              <a-input v-model="form.position" />
            </a-form-model-item>
            <a-form-model-item
              label="高校名称"
              prop="company"
              v-show="form.authtype == '高校教师'"
            >
              <a-input v-model="form.company" />
            </a-form-model-item>

            <a-form-model-item
              label="二级学院"
              prop="college"
              v-show="form.authtype == '高校教师'"
            >
              <a-input v-model="form.college" />
            </a-form-model-item>
            <a-form-model-item ref="email" :label="form.authtype=='高校教师'?'学校邮箱':'公司信箱'" prop="email">
              <a-input v-model="form.email" />
            </a-form-model-item>

            <a-form-model-item
              label="微信号"
              prop="vxid"
              v-show="form.authtype == '高校教师'"
            >
              <a-input v-model="form.vxid" />
            </a-form-model-item>
            <a-form-model-item
              label="微信服务请扫码："
          
          
            >
            <img  src="../assets/WechatIMG16153.jpg" style="width:70px"/>
            </a-form-model-item>
            <!-- <a-form-model-item
              label="请描述您需要咨询的内容"
              prop="desc"
      
            >
        
              <a-textarea placeholder="" auto-size  v-model="form.desc"/>
            </a-form-model-item> -->
            <!-- <a-form-model-item
              label="课程或功能"
              prop="course"
              v-show="form.authtype == '高校用户'"
            >
              <a-select
                v-model="form.course"
                placeholder="请选择课程或功能"
                mode="multiple"
              >
                <a-select-option
                  v-for="(item, index) in courselist"
                  :value="item._id"
                  :key="index"
                >
                  {{ item.title }}
                </a-select-option>
              </a-select>
            </a-form-model-item> -->
            <!-- <a-form-model-item
              label="微信号"
              prop="vxid"
              style="visibility: hidden"
            >
              <a-input v-model="form.vxid" />
            </a-form-model-item> -->
            <!-- <el-divider></el-divider> -->
            <a-form-model-item style="width:100%;text-align: center;" class="sub">
              <a-button type="primary" @click="onSubmit" :disabled="subdisabled">
                {{ form.authtype  ? `【${form.authtype}】` : "" }} 提交
              </a-button>

              <!-- <a-button style="margin-left: 10px" @click="resetForm">
              重置
            </a-button> -->
            </a-form-model-item>
         
          </a-form-model>
   
          <a-result
            v-else
            status="success"
            title="您已提交成功，稍后会有工作人员联系及服务！"
            sub-title=""
          >
            <!-- <template #extra>
                <a-button key="console" type="primary"> Go Console </a-button>
                <a-button key="buy"> Buy Again </a-button>
              </template> -->
          </a-result>
          <!-- <div  style="color: #000; font-size: 20px; font-weight: bold"> -->

          <!-- </div> -->
        <!-- </a-tab-pane> -->
        <!-- <a-tab-pane key="2" force-render>
            <span slot="tab">
              <a-icon type="cluster" />
              免 费 开 课
            </span>
            <a-form-model
              v-if="subSureKK"
              ref="ruleFormKK"
              :model="formKK"
              :rules="rulesKK"
              :label-col="labelCol"
              :wrapper-col="wrapperCol"
            >

              <a-form-model-item label="高校名称" prop="company">
                <a-input v-model="formKK.company" />
              </a-form-model-item>
              <a-form-model-item label="学院" prop="college">
                <a-input v-model="formKK.college" />
              </a-form-model-item>

              <a-form-model-item label="姓名" prop="name">
                <a-input v-model="formKK.name" />
              </a-form-model-item>

              <a-form-model-item label="手机号" prop="phone">
                <a-input v-model="formKK.phone" />
              </a-form-model-item>

              <a-form-model-item label="课程并发实验人数" prop="number">
                <a-input v-model="formKK.number" />
              </a-form-model-item>

              <a-form-model-item label="课程" prop="course">
                <a-select
                  v-model="formKK.course"
                  placeholder="请选择课程"
                  mode="multiple"
                >
                  <a-select-option
                    v-for="(item, index) in courselist"
                    :value="item._id"
                    :key="index"
                  >
                    {{ item.title }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="邮箱" prop="email">
                <a-input v-model="formKK.email" />
              </a-form-model-item>
              <a-form-model-item label="微信号" prop="vxid">
                <a-input v-model="formKK.vxid" />
              </a-form-model-item>
              <a-form-model-item
              class="mi"
                label="上传"
                prop="file"
                style="position: relative"
              >
      
                <a-button> <a-icon type="upload" /> 上传图片 </a-button> <span style="margin-left:10px;">{{fileName}}</span>
                <input
                  type="file"
                  name="myfile"
                  accept="image/jpg, image/png"
                  style="
                    position: absolute;
                    top: -5px;
                    height: 30px;
                    width: 110px;
                    opacity: 0;
                  "
                      
                  @change="changeFile"
                />
                <span style="color:red;display: block;">【 请上传含本人名字的教务系统截图 】</span>
         
              </a-form-model-item>
              <a-form-model-item
                label="微信号"
                prop="vxid"
                style="visibility: hidden"
              >
                <a-input v-model="form.vxid" />
              </a-form-model-item>
              <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
                <a-button type="primary" @click="onSubmitKK">
                  申请开课
                </a-button>
     
              </a-form-model-item>
            </a-form-model>
            <a-result
              v-else
              status="success"
              title="您已提交成功，稍后会有工作人员联系及服务！"
              sub-title=""
            >

            </a-result>
          </a-tab-pane> -->
        <!-- <a-tab-pane key="2" force-render>
          <span slot="tab">
            <a-icon type="tablet" />
            服 务 热 线
          </span>
          <div
            style="
              color: #333333;
              font-size: 18px;
              font-weight: bold;
              margin-top: 30px;
            "
          >
            <div style="margin-bottom: 30px">
              <p style="color: #d81e06; font-size: 16px">
             
                服务热线
              </p>
              <span>400xxxxxxxxxxx</span>
            </div>
            <div>
              <p style="font-size: 16px">关注希冀平台公众号</p>
              <img src="../assets/qrcode.jpg" width="120px" />
            </div>
          </div>
        </a-tab-pane>
      </a-tabs> -->
    
    </div>

    <footer>
        <div class="modal fade" tabindex="-1" role="dialog" id="qqmodal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">CG教学平台高校教师QQ群</h4>
            </div>
            <div class="modal-body" style="text-align: center;">
                <p style="margin-bottom:15px;">QQ群号：<a href="https://jq.qq.com/?_wv=1027&k=42nfrUH" style="color:#0880e8">214085215 </a> （仅限教师，请注明：学校+姓名） </p>
                <p align="center"><img src="https://www.educg.net/images/214085215.png" height="300" /></p>

            </div>

        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
<div class="modal fade" tabindex="-1" role="dialog" id="qdmodal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span
                        aria-hidden="true">&times;</span></button>
                <h4 class="modal-title">渠道合作请扫二维码</h4>
            </div>
            <div class="modal-body">
                <p>请注明：学校/公司 + 姓名</p>
                <p align="center"><img src="https://www.educg.net/contact/weixin.png" height="300" /></p>

            </div>

        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div>
    <div class="container">



        <div class="row">
            <div class="col-sm-3 col-lg-3">
                <div class="widget">
                    <h4>专业建设方案</h4>

                    <ul class="link-list">
                        <li><a href="https://www.educg.net/aibd.html" target="_blank">人工智能</a></li>
                        <li><a href="https://www.educg.net/bigdata.html" target="_blank">大数据</a></li>
                        <li><a href="https://www.educg.net/csc.html" target="_blank">计算机/系统能力培养</a></li>
                        <li><a href="https://www.educg.net/infosec.html" target="_blank">信息安全</a></li>
                        <li><a href="https://www.educg.net/blockchain.html" target="_blank">区块链</a></li>
                        <li><a href="https://www.educg.net/ic.html" target="_blank">集成电路</a></li>
                        <li><a href="https://www.educg.net/medical.html" target="_blank">智能医学</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="widget">
                    <h4>教育数字基建</h4>

                    <ul class="link-list">
                      <li><a href="https://ai4s.educg.com/plugin/main/" target="_blank">大模型平台</a></li>
                        <li><a href="https://www.educg.net/research.html" target="_blank">智能算力服务平台</a></li>

                        <li><a href="https://www.educg.net/OBE.html" target="_blank">工程教育认证</a></li>
                        <li><a href="https://www.educg.net/graduation.html" target="_blank">毕业设计</a></li>
                        <li><a href="https://www.educg.net/scangrade.html" target="_blank">纸质试卷在线评阅</a></li>
                        <li><a href="https://www.educg.net/exam.html" target="_blank">在线考试</a></li>
                        <li><a href="https://www.educg.net/mooc.html" target="_blank">慕课</a></li>
                        <li><a href="https://plag.educg.net" target="_blank">图文与代码查重</a></li>
                        <li><a href="https://eduxiji.com" target="_blank">理工类专业智慧教学平台</a></li>
                        <li><a href="https://bd.educg.net" target="_blank">教育大数据平台</a></li>
                        <li><a href="https://www.xuezhiqiao.com" target="_blank">新形态数字教材平台</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="widget">
                    <h4>教学实验平台建设方案</h4>

                    <ul class="link-list">
                      <li><a href="https://fpga.educg.net/" target="_blank">FPGA虚拟结合在线实验平台</a></li>
                        <li><a href="https://www.educg.net/robot.html" target="_blank">云端机器人实验平台</a></li>
                        <li><a href="https://www.educg.net/cloudDesk.html" target="_blank">新一代云桌面实验平台</a></li>
                        <!-- <li><a href="https://www.educg.net/fpga-cloud.html">FPGA实验云平台</a></li> -->
                        <li><a href="https://3desk.educg.com/" target="_blank">3D云桌面</a></li>
                        <li><a href="https://www.educg.net/contest.html" target="_blank">比赛</a></li>
                        <li><a href="https://www.educg.net/oj.html" target="_blank">OnlineJudge</a></li>
                        <li><a href="https://www.educg.net/exp.html" target="_blank">在线虚拟实验开发环境</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-sm-3 col-lg-3">
                <div class="widget">
                    <h4>课程建设方案</h4>

                    <ul class="link-list">
                        <li><a href="https://www.educg.net/programming.html" target="_blank">程序设计</a></li>
                        <li><a href="https://www.educg.net/ds.html" target="_blank">算法与数据结构</a></li>
                        <li><a href="https://www.educg.net/os.html" target="_blank">操作系统</a></li>
                        <li><a href="https://www.educg.net/computer.html" target="_blank">计算机组成原理</a></li>
                        <li><a href="https://www.educg.net/compiler.html" target="_blank">编译技术实验</a></li>
                        <li><a href="https://www.educg.net/database.html" target="_blank">数据库实验</a></li>
                        <li><a href="https://www.educg.net/se.html" target="_blank">软件工程</a></li>
                        <li><a href="https://www.educg.net/parallel.html" target="_blank">并行计算</a></li>
                        <li><a href="https://www.educg.net/network.html" target="_blank">计算机网络</a></li>
                        <li><a href="https://www.educg.net/ai.html" target="_blank"> 人工智能类课程</a></li>
                        <li><a href="https://www.educg.net/bigdata.html" target="_blank">大数据类课程</a></li>
                        <li><a href="https://www.educg.net/blockchain.html" target="_blank">区块链类课程</a></li>
                        <li><a href="https://www.educg.net/applied_courses.html" target="_blank">应用开发类课程</a></li>
                        <li>......</li>
                    </ul>
                </div>
            </div>
        </div>

        <div style="border-bottom: 1px solid #ccc;"></div>
        <br>

        <!-- <div class="row">
            <div class="col-sm-2 col-lg-2" style="border-right: 1px solid #ccc;">
                <div class="widget">

                    <h4>联系我们</h4>

                    <p> <strong>产品合作</strong><br>
                        <i class="icon-phone"></i> 19910509699 <br>

                        <br>
                        <a  href="javascript:;" aria-expanded="false" aria-controls="collapseweixinbusiness" style="text-decoration: none;">
                            <svg style="    position: relative;
    top: 5;"  t="1694484894911" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5855" width="22" height="22"><path d="M669.103 367.838c10.238 0 20.366 0.75 30.413 1.87-27.328-127.25-163.368-221.792-318.656-221.792-173.605 0-315.816 118.336-315.816 268.6 0 86.735 47.312 157.962 126.375 213.206l-31.587 95.004 110.404-55.364c39.504 7.82 71.2 15.861 110.623 15.861 9.91 0 19.74-0.491 29.485-1.257-6.17-21.116-9.746-43.231-9.746-66.175 0.001-137.978 118.486-249.953 268.505-249.953z m-169.81-85.63c23.778 0 39.53 15.644 39.53 39.41 0 23.669-15.752 39.531-39.53 39.531-23.672 0-47.423-15.86-47.423-39.531 0-23.765 23.751-39.41 47.422-39.41zM278.263 361.15c-23.67 0-47.558-15.86-47.558-39.531 0-23.766 23.887-39.41 47.558-39.41 23.67 0 39.422 15.644 39.422 39.41 0 23.67-15.753 39.531-39.422 39.531z" fill="#3c763d" p-id="5856"></path><path d="M957.344 613.996c0-126.267-126.347-229.19-268.257-229.19-150.265 0-268.612 102.924-268.612 229.19 0 126.485 118.347 229.19 268.612 229.19 31.451 0 63.174-7.93 94.762-15.847l86.624 47.434-23.752-78.925c63.393-47.56 110.623-110.625 110.623-181.852z m-355.348-39.518c-15.725 0-31.586-15.643-31.586-31.602 0-15.737 15.861-31.586 31.586-31.586 23.891 0 39.534 15.849 39.534 31.586 0 15.959-15.643 31.602-39.534 31.602z m173.717 0c-15.617 0-31.369-15.643-31.369-31.602 0-15.737 15.753-31.586 31.369-31.586 23.669 0 39.531 15.849 39.531 31.586 0 15.959-15.862 31.602-39.531 31.602z" fill="#3c763d" p-id="5857"></path></svg> 商务联络</a>
                    </p><div class="collapse in" id="collapseweixinbusiness" ><img src="https://www.educg.net/contact/weixin.png" height="130">
                    </div>
                    <p></p>



                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="widget">
                    <h4>服务与支持</h4>
                    <ul class="link-list">
                        <li><a href="https://www.educg.net/onlinecourse.html">在线开课</a></li>
                        <li><a href="https://test.csia.org.cn/" target="_blank">全国青少年信息技术与计算思维能力评测(TCTY)</a></li>
                        <li><a href="https://test.csia.org.cn/" target="_blank">全国青少年人工智能应用与创新能力评测（AITY）</a></li>

                        <li><a href="https://sat.csia.org.cn/" target="_blank">信息技术人才-软件能力评测</a></li>
                        <li><a href="https://compiler.educg.net" target="_blank">计算机系统能力大赛-编译赛道官网</a></li>
                        <li><a href="https://os.educg.net" target="_blank">计算机系统能力大赛-操作系统赛道官网</a></li>
                        <li><a href="https://db.educg.net" target="_blank">计算机系统能力大赛-数据库赛道官网</a></li>
                        <li><a href="https://eduxiji.com" target="_blank">理工类专业智慧教学平台</a></li>
                        <li><a href="javascript:qqWindow()">加入高校教师QQ群</a></li>

                    </ul>
                </div>

            </div>

            <div class="col-sm-4 col-lg-4">
                <div class="widget">
                    <h4>快速入口</h4>
                    <ul class="link-list">
                        <li><a href="https://www.educg.net/download.html" target="_blank">平台升级记录</a></li>
                        <li><a href="https://www.educg.net/joinin.html">加入我们</a></li>
                        <li><a  href="javascript:qdWindow()">渠道合作</a>
                        </li>
                        <li><a href="https://pan.educg.net/#/s/kdkF5" target="”_blank”">教育部产学合作协同育人申报</a>
                        </li>
                        <li><a href="https://pysk.educg.net/" target="_blank">计算机类专业建设、工程教育认证专题访谈节目--鹏友时刻</a></li>
                        <li><a href="https://www.educg.net/download-papers.html">平台相关论文</a></li>
                        <li><a href="https://www.educg.net/project.html">项目汇总</a> </li>
       
                    </ul>

                </div>
            </div>
            <div class="col-sm-2 col-lg-2">
                <div class="widget">
                    <h4>关注希冀平台公众号</h4>

                    <img src="https://www.educg.net/images/qrcode.jpg" height="160">

                </div>
            </div>

        </div> -->
        <div class="row">
            <div class="col-sm-2 col-lg-2" style="border-right: 1px solid #ccc;">
                <div class="widget">

                    <h4>联系我们</h4>
<!-- 					
					<p> <strong>产品合作</strong><br>
					    <i class="glyphicon glyphicon-phone-alt"></i> 19910509699 <br>
					</p>
					<br>
					
					<p> <strong>服务热线</strong><br>
					    <i class="glyphicon glyphicon-phone-alt"></i> 4000697117 <br>
					</p>
					<br>
					<p><a href="https://devms.t.educg.com/#/zl">
					<i class="far fa-comments" style="font-size: 23px; color: #7C1212"> </i> <span style="font-weight: bold; font-size: 18px;color: #7C1212;">在线咨询</span></a></p>
					<br>
					<br> -->
          <ul class="ul-add-li">
                        <li>
                            <div style="margin-top:5px;">产品合作</div>
                            <div style="margin-top:5px;"><i class="fas fa-phone-volume"></i> 19910509699 </div>
                        </li>
                        <li style="margin-top:30px;">
                            <div style="margin-top:5px;">服务热线</div>
                            <div style="margin-top:5px;"><i class="fas fa-phone-volume"></i> 4000697117  </div>
                        </li>
                        <!-- <li>
                            <a href="https://devms.t.educg.com/#/zl" class="cta-btn align-middle">
                                <i class="far fa-comments" style="font-size: 23px; color: #7C1212"> </i> <span style="font-weight: bold; font-size: 18px;" class="text-danger">在线咨询</span></a>
                        </li> -->
                        <li>
                            <a href="https://devms.t.educg.com/#/zl" class="cta-btn align-middle">
                                <i class="far fa-comments" style="font-size: 23px; color: #7C1212"> </i> <span style="font-weight: bold; font-size: 18px;" class="text-danger">在线咨询</span></a>
                        </li>

                    </ul>



                </div>
            </div>
            <div class="col-sm-4 col-lg-4">
                <div class="widget">
                    <h4>服务与支持</h4>
                    <ul class="link-list">
                        <li><a href="https://www.educg.net/onlinecourse.html" target="_blank">在线开课</a></li>
                        <li><a href="https://test.csia.org.cn/" target="_blank">全国青少年信息技术与计算思维能力评测(TCTY)</a></li>
                        <li><a href="https://test.csia.org.cn/" target="_blank">全国青少年人工智能应用与创新能力评测(AITY)</a></li>

                        <li><a href="https://sat.csia.org.cn/" target="_blank">信息技术人才-软件能力评测</a></li>
                        <li><a href="https://compiler.educg.net" target="_blank">计算机系统能力大赛-编译赛道官网</a></li>
                        <li><a href="https://os.educg.net" target="_blank">计算机系统能力大赛-操作系统赛道官网</a></li>
                        <li><a href="https://db.educg.net" target="_blank">计算机系统能力大赛-数据库赛道官网</a></li>
                        <li><a href="javascript:;">理工类专业智慧教学平台</a></li>
                        <li><a href="javascript:qqWindow()">加入高校教师QQ群</a></li>

                    </ul>
                </div>

            </div>

            <div class="col-sm-4 col-lg-4">
                <div class="widget">
                    <h4>快速入口</h4>
                    <ul class="link-list">
                        <li><a href="https://www.educg.net/download.html" target="_blank">平台升级记录</a></li>
                        <li><a href="https://www.educg.net/joinin.html" target="_blank">加入我们</a></li>
                        <li><a href="https://devms.t.educg.com/#/zl" target="_blank">渠道合作</a>
                        </li>
                        <li><a href="https://pan.educg.net/#/s/kdkF5" target="”_blank”">教育部产学合作协同育人申报</a>
                        </li>
                        <li><a href="javascript:;" >计算机类专业建设、工程教育认证专题访谈节目--鹏友时刻</a></li>
                        <li><a href="https://www.educg.net/download-papers.html">平台相关论文</a></li>
                        <!--
						<li><a href="creacu-books.html" target="_blank">高等学校计算机教育2022年课题申报指南</a></li>
						-->
                    </ul>

                </div>
            </div>
			
            <div class="col-sm-2 col-lg-2">
                <div class="widget" style="text-align: center;">
                    <h4>希冀平台公众号</h4>

                    <img src="https://www.educg.net/images/qrcode.jpg" height="150">
					
                </div>
				
            </div>

        </div>



    </div>
    <div id="sub-footer" style="background:#f2f2f2;text-align: center;">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="copyright">
                        <p style="font-size:14px;">© <strong>北京航空航天大学 计算机学院 教学成果转化平台</strong> |
                            中国软件行业协会高等教育产教融合专业委员会秘书处 | 郑州云海科技有限公司 | 阔思格睿网络科技（北京）有限责任公司</p>
                        <!-- <br>
                        <div class="credits">
                            <a href="https://beian.miit.gov.cn/" target="_blank">豫ICP备18015569号</a>

                            |
                            <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"><img src="https://www.educg.net/beian/gongan.png">豫公网安备 41010702002654号</a>


                            <address> 办公地址：郑州市商都路100号建正东方中心C座2209 &nbsp;</address>

                        </div> -->
                        <p style="color:#f6b024;margin:5px 0;">

                          <a href="https://beian.miit.gov.cn/" target="_blank"
                              style="    color: #f6b024;">豫ICP备18015569号</a> | <a target="_blank"
                              style="margin:24px 0 ;text-decoration: none;    color: #f6b024"
                              href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=41010702002654"><img
                                  style='vertical-align: middle;' src="../assets/gongan.png">豫公网安备
                              41010702002654号</a>
                          </p>
                          <p style="color:#000;margin-bottom:20px;">办公地址：郑州市商都路100号建正东方中心C座2209 北京市海淀区锦秋国际大厦A座1404 </p>

                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>
  </div>
</template>


<script>
export default {
  data() {
    return {
      titleD:'试用及预约',
      scllorTp:false,
        subdisabled:false,
      fileList: [],
      fileName: "",
      subSure: true,
      subSureKK: true,
      courselist: [],
      labelCol: { span: 5 },
      wrapperCol: { span: 17 },
      other: "",
      form: {
        authtype: undefined,
        phone: "",
        position: "",
        college: "",
        company: "",
        companyW: "",
        name: "",
        vxid: "",
        email: "",
        course: [],
      },
      formKK: {
        email: "",
        authtype: undefined,
        phone: "",
        position: "",
        college: "",
        company: "",
        vxid: "",
        name: "",
        number: "",
        file: "",
        course: [],
        // desc:''
      },
      rules: {
        // desc: [
        //   {
        //     required: true,
        //     message: "请描述您需要咨询的内容",
        //     trigger: "blur",
        //   },

        //   {
        //     min: 2,
        //     max: 30,
        //     message: "请输入2~100字符",
        //     trigger: "blur",
        //   },
        // ],
        name: [
          {
            required: true,
            message: "请输入您的姓名",
            trigger: "blur",
          },

          {
            min: 2,
            max: 30,
            message: "请输入2~30字符",
            trigger: "blur",
          },
        ],
        position: [
          {
            min: 2,
            max: 30,
            message: "请输入2~30字符",
            trigger: "blur",
          },
        ],
        college: [
          {
            required: true,
            message: "请输入院校",
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: "请输入2~30字符",
            trigger: "blur",
          },
        ],
        vxid: [
          {
            min: 1,
            max: 30,
            message: "请输入1~30字符",
            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入手机号",
            trigger: "blur",
          },
          {
            pattern:
              /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$|^(853\d{7})$|^(886\d{9})$/,
            message: "请输入正确手机号",
          },
        ],
        authtype: [
          {
            required: true,
            message: "请选择身份",
            trigger: "change",
          },
        ],
        company: [
          {
            required: true,
            message: "请输入高校名称",
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: "请输入2~30字符",
            trigger: "blur",
          },
        ],
        email: [
          // {
          //     required: true,
          //     message: "请输入手机号",
          //     trigger: "blur",
          //   },
          {
            pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
            message: "请输入正确邮箱",
          },
        ],

        companyW: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: "请输入2~30字符",
            trigger: "blur",
          },
        ],
      },
      rulesKK: {
        // file: [
        //   {
        //     required: true,
        //     message: "",
        //     trigger: "change",
        //   },
        // ],
        name: [
          {
            required: true,
            message: "请输您的您的姓名",
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: "请输入2~20字符",

            trigger: "blur",
          },
        ],
        phone: [
          {
            required: true,
            message: "请输入联系电话",
            trigger: "blur",
          },
          {
            pattern:
              /^(1[3-9]\d{9})$|^(852\d{8})$|^(853\d{8})$|^(886\d{8})$|^(853\d{7})$|^(886\d{9})$/,
            message: "请输入正确手机号",
          },
        ],

        company: [
          {
            required: true,
            message: "请输入学校名称",
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: "请输入2~30字符",
            trigger: "blur",
          },
        ],
        college: [
          {
            required: true,
            message: "请输入二级学院",
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: "请输入2~30字符",
            trigger: "blur",
          },
        ],
        number: [
          {
            required: true,
            message: "请输入课程并发实验人数",
            trigger: "blur",
          },
          {
            min: 2,
            max: 30,
            message: "请输入2~30字符",
            trigger: "blur",
          },
        ],
        email: [
          // {
          //     required: true,
          //     message: "请输入手机号",
          //     trigger: "blur",
          //   },
          {
            pattern: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/,
            message: "请输入正确邮箱",
          },
        ],
        course: [
          {
            required: true,
            message: "请选择课程或功能",
            trigger: "change",
          },
        ],
      },
    };
  },
  mounted() {
    this.courselistFun();
    if(this.$route.query.educg){
      document.title = "预约演示";
      this.titleD = '预约演示'
    }
    else {
      document.title = "试用及咨询";
      this.titleD = '试用及咨询'
    }

    
    window.addEventListener("scroll", this.scrolling);
  },

  methods: {
    scrolling() {
      // 滚动条距文档顶部的距离
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条滚动的距离
      let scrollStep = scrollTop - this.oldScrollTop;
      console.log("header 滚动距离 ", scrollTop);
      if(scrollTop >100){
        this.scllorTp = true

      }
      else {this.scllorTp = false}
      // 更新——滚动前，滚动条距文档顶部的距离
      // this.oldScrollTop = scrollTop;

      // //变量windowHeight是可视区的高度
      // let windowHeight =
      //   document.documentElement.clientHeight || document.body.clientHeight;
      // //变量scrollHeight是滚动条的总高度
      // let scrollHeight =
      //   document.documentElement.scrollHeight || document.body.scrollHeight;

      // //滚动条到底部的条件
      // if (scrollTop + windowHeight == scrollHeight) {
      //   //你想做的事情
      //   console.log("header  你已经到底部了");
      // }
      // if (scrollStep < 0) {
      //   console.log("header 滚动条向上滚动了！");
      // } else {
      //   console.log("header  滚动条向下滚动了！");
      // }
      // // 判断是否到了最顶部
      // if (scrollTop <= 0) {
      //   console.log("header 到了最顶部")
      // } 
    

},

    // qdWindow() {
    //     document.getElementById('#qdmodal').modal('show');
    //     // $('#qdmodal')
    // },
    changeFile(event) {
      console.log(event.target.files[0]);
      debugger;
      const isLt2M = event.target.files[0].size / 1024 / 1024 < 1;
      if (!isLt2M) {
        this.$message.error("图片大于1MB!");
        return;
      }
      this.fileName = event.target.files[0].name;
      this.formKK.file = event.target.files[0];
    },

    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        console.log(this.fileList);
        console.log(file);
        if (this.fileList.length >= 1) {
          this.$message.error("只能上传一张图片！");
          return reject(false);
        }
        const isJpgOrPng =
          file.type === "image/jpeg" || file.type === "image/png";
        // //   if (!isJpgOrPng) {
        // //     this.$message.error('You can only upload JPG file!');
        // //   }
        const isLt2M = file.size / 1024 / 1024 < 1;
        if (!isLt2M) {
          this.$message.error("图片大于1MB!");
          return reject(false);
        }
        this.formKK.file = file;
        return resolve(true);
      });
    },
    courselistFun() {
      let _this = this;
      let data = {
        op: "courselist",
      };
      this.$ajax
        .post(this.contact, _this.Qs.stringify(data))
        .then(function (response) {
          console.log(response);
          if (response.data.value == "ok") {
            _this.courselist = response.data.data;
          } else {
            _this.$message.success(response.data.msg);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    tabcallback(key) {
      console.log(key);
    },
    authtypeFun() {
      this.form.company = "";
      this.form.college = "";
      this.form.companyW = "";
      this.form.position = "";
      // if(this.form.authtype!=''){
      //   this.subdisabled = false;
      // }
      // if(this.form.authtype=='高校用户/学生'){
      //   this.$message.success('您好，请联系您的相关任课教师处理您的问题');
      //   this.subdisabled = true;
      // }
      // else {
        
      // }
      this.subdisabled = false;
    },
    backFun(){
        window.location.href='https://www.educg.net/';return false
    },
    onSubmit() {
      if (this.form.authtype == "企业合作") {
        this.form.company = "暂无";
        this.form.college = "暂无";
      } else {
        this.form.companyW = "暂无";
        this.form.position = "暂无";
      }
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
         
          let data = {
            op: "submit",
            name: this.form.name,
            phone: this.form.phone,
            email: this.form.email,
            authtype: this.form.authtype,
            // desc: this.form.desc,
            type: "咨询",
            from:this.$route.query.type || ''
          };
          if (this.form.authtype == "企业合作") {
            data.company = this.form.companyW;
            data.position = this.form.position;
          } else {
            data.company = this.form.company;
            data.college = this.form.college;
            data.course = this.form.course;
            data.vxid = this.form.vxid;
          }
          console.log(this.form.course);

          let _this = this;
          this.$ajax
            .post(this.contact, _this.Qs.stringify(data))
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$message.success(response.data.msg);
                _this.subSure = false;
              } else {
                _this.$message.success(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    onSubmitKK() {
      this.$refs.ruleFormKK.validate((valid) => {
        if (valid) {
          if (this.formKK.file == "") {
            this.$message.info("请上传图片");
            return;
          }
          let _this = this;
          let FormDatas = new FormData();
          FormDatas.append("op", "submit");
          FormDatas.append("name", this.formKK.name);
          FormDatas.append("phone", this.formKK.phone);
          FormDatas.append("company", this.formKK.company);
          FormDatas.append("college", this.formKK.college);
          FormDatas.append("number", this.formKK.number);
          FormDatas.append("email", this.formKK.email);
          this.formKK.course.forEach((value, index) => {
            //   fd.append(`address[${index}]`, value)
            FormDatas.append(`course[${index}]`, value);
          });

          FormDatas.append("type", "开课");
          FormDatas.append("vxid", this.formKK.vxid);
          FormDatas.append("file", this.formKK.file);

          //   let data = {
          //     op: "submit",
          //     name: this.formKK.name,
          //     phone: this.formKK.phone,
          //     company: this.formKK.company,
          //     college: this.formKK.college,
          //     number: this.formKK.number,
          //     email: this.formKK.email,
          //     course: this.formKK.course,
          //     // authtype:this.formKK.authtype,
          //     type: "开课",
          //     vxid: this.formKK.vxid,
          //     file:
          //   };
          this.$ajax
            .post(this.contact, FormDatas)
            .then(function (response) {
              console.log(response);
              if (response.data.value == "ok") {
                _this.$message.success(response.data.msg);
                _this.subSureKK = false;
              } else {
                _this.$message.success(response.data.msg);
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    resetFormKK() {
      this.$refs.ruleFormKK.resetFields();
    },
  },
};
</script>

  <style lang="scss">
   @import '../assets/css/bootstrap.min.css';
   @import '../assets/fs5/css/all.css';

   
  
div,
p,
ul,
li,
input,
body {
  padding: 0;
  margin: 0;
  list-style: none;
}

.mi label::before {
  display: inline-block;
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}
#sub-footer {
    border-top: 1px solid #ddd;
    background: #f7f7f7;
    text-shadow: none;
    padding: 0;
    padding-top: 30px;
    margin: 20px 0 0 0;
}
.ant-form-item-label{
  text-align: left;;
}
.ul-add-li li,.link-list li{
  padding-bottom:10px;
}
/* tile uploaded pictures */
// .upload-list-inline >>> .ant-upload-list-item {
//   float: left;
//   width: 200px;
//   margin-right: 8px;
// }
// .upload-list-inline >>> .ant-upload-animate-enter {
//   animation-name: uploadAnimateInlineIn;
// }
// .upload-list-inline >>> .ant-upload-animate-leave {
//   animation-name: uploadAnimateInlineOut;
// }
.zl_box {
  position: relative;
  //   height: 340px;
  //   width: 100%;
  //   background: #0675c0;

  background-size: cover;
  .mask {
    position: absolute;
    background: #000;
    opacity: 0.3;
    // height: 277px;
    bottom: 0;
    width: 100%;
    top: -60px;
    z-index: 0;
    // border-radius: 46px;
  }
}
.zl{
  background:#fff;
  position: relative;
}
.box-header {
  background: rgba(255, 255, 255);
  position: sticky;
  display: flex;
  justify-content: space-between;
  padding: 20px;
  top: 0;
  box-shadow: 1px 1px 5px 2px #dadada;
  z-index: 100;

}
.sub .ant-col-17{
  width:100%;
}
.rightlogo{
  // background: url(https://www.jq22.com/demo/jqueryxysmoban201909092346/dist/images/header-bg-right.svg) right top no-repeat;
  background: url(https://www.qmth.com.cn/img/ny_yyys_banner_bg.jpg) no-repeat;
 
    background-size:cover
}
.cta-btn{
  color: rgba(255, 255, 255, 0.75);
    transition: 0.3s;
    display: inline-block;
    line-height: 1;
    font-size: 14px;
    font-weight: 500;
    font-size: 15px;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 6px 16px;
    border-radius: 2px;
    transition: 0.5s;
    margin: 10px;
    border-radius: 50px;
    // border: 2px solid #f6b024;
    color: #fff;
    // background: #f6b024;
}
.con {
  position: relative;
  width: 1000px;
  margin: 30px auto;
  margin-top: -250px;
    // background: #fff;
    padding: 50px ;
    z-index:99;
    // background: url(https://www.jq22.com/demo/jquerybootstrapsGsqymoban202107021414/assets/images/service/line-three.png)  no-repeat; 
    // height:70vh;
    background-position: right;
    background-size:3% ;
    .from-title{
      text-align: center;
      margin-bottom:50px;
    }
    .ant-btn-primary{
    //   background-color: #5c54ff;
    // border-color: #5c54ff;
    }
    // box-shadow: 1px 1px 4px 1px #f1f1f1;
  // .el-input__inner{
  //     background:#ffffff;
  //     border: 1px solid #e6e2e2;
  //     color: #57595a;
  // }
  // .el-select .el-input .el-select__caret{
  //     color:#e6e2e2;
  // }
  // .el-select-dropdown,.el-select .el-input.is-focus .el-input__inner{
  //     border: 1px solid #e6e2e2;    background-color: #fff;
  // }
  // .el-select-dropdown__item{
  //     color: #4a4949;
  // }
  // .el-select-dropdown{
  //     border: 1px solid #e6e2e2;    background-color: #fff;
  // }
  .ant-form{
    width: 100%;
    background: #fff;
    padding: 50px 133px;
    margin: 0 auto;
    box-shadow: 0px 5px 16.74px 1.26px rgba(60, 57, 90, 0.14);
    border-radius: 10px;

  }
  .mask {
    position: absolute;
    background: #000;
    opacity: 0.5;
    height: 277px;
    width: 100%;
    top: -60px;
    z-index: 0;
    border-radius: 46px;
  }
  .ant-form {
    text-align: left;
  }
  .ant-form-item {
    width:100%;
  
    display: inline-block;
  }
  .sub .ant-col-14{
    width:100%;
  }

  .kh {
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .el-divider {
    background-color: #ffffff;
  }
}
.conDD {
  padding:150px 0 50px;
  text-align: center;
  // background: url(https://www.jq22.com/demo/jquerybootstrapJymoban202107231802/assets/images/bg-img/01.jpg) no-repeat;
  height:60vh;
  background: url(../assets/ysys.jpg) no-repeat;
 
 background-size:cover;

  color:#fff;

    h1{
      font-size:50px;
      position: relative;
  z-index: 99;
    }

    p {
    color: #333;

    font-size: 17px;
    font-weight: 500;
   

    span {
      margin: 0 10px;
    }
  }
}

@media screen and (max-width: 1240px) {
  .con {
    width: 90%;
  }
  .minH {
    width: 80px;
  }
  .minF{
    font-size:12px !important;
    display: none;
  }
  .zl .ant-divider-inner-text{
    padding: 0 !important;
  }
  .divider{
    font-size:14px !important;
    i{font-size:14px !important}
  }
}
@media screen and (max-width: 1000px) {
  .zl_box h1{
    font-size:30px;
  }
  .conDD{
    padding-top:70px;
  }
}
@media screen and (max-width: 971px) {
  .con{
    padding:0 !important;
    width:97%;
  }
  .con .ant-form{
    padding-left:10px;
    padding:10px;
  }
  .zl_box h1{
    font-size:20px;
  }
  .zl_box .sc-window{
    font-size: 12px !important
  }
}
@media screen and (max-height: 860px) {
  .con{
    margin-top:-260px;
  }
}
.zl footer{
    text-align: left;
    padding: 50px 0 0 0;
    background: #f2f2f2;
    a{
        color:#777
    }
}
.zl footer ul.link-list li a:hover {
    color: #333;
    text-decoration: none;
}
.zl a:hover{
    color: #333;
    text-decoration: none;
}
// .ant-form-item{
//     margin-bottom:12px;
// }
.el-message--success{
    padding:40px !important;
    // background: #ccc;
}
</style>
  